import {ApiQueryParams} from '@core/models';
import {firstValueFrom} from 'rxjs';
import {CrudEndPoint} from '@core/const';
import {Payload} from '@core/models/payload';
import {IdentifierReference} from '@core/models/identifier-reference';
import {QueryApiService} from '@core/crud/query-api.service';
import {instanceOfBaseCompanyBranch} from '@core/classes/base-company-branch';

export abstract class CrudApiService<T, R extends IdentifierReference> extends QueryApiService<T, R> {

  protected constructor(endpoint: CrudEndPoint) {
    super(endpoint);
  }

  async query(page: number, size: number, params?: ApiQueryParams): Promise<Payload<T>> {
    const queryParams: ApiQueryParams = {
      ...this.getDefaultParams(),
      page: page,
      size: size,
      ...params
    };
    return (await this.getWithParams(queryParams)) as Payload<T>;
  }

  async get(id: number): Promise<T> {
    return (await firstValueFrom(this.apiService.get(`${this.endPoint}/${id}`))) as T;
  }

  async post(dto: T): Promise<T> {
    return await firstValueFrom(this.apiService.post<T, T>(this.endPoint, dto));
  }

  async put(id: number, dto: T): Promise<T> {
    return await firstValueFrom(
      this.apiService.put<T, T>(`${this.endPoint}/${id}`, dto)
    );
  }

  async delete(id: number): Promise<boolean> {
    return await firstValueFrom(
      this.apiService.delete(`${this.endPoint}/${id}`)
    );
  }

  getEndPoint(): CrudEndPoint {
    return this.endPoint;
  }

  async existsElement<T>(entity: T, searchBy: string, touched?: boolean): Promise<boolean> {
    const queryParams: ApiQueryParams = {};
    const searchValue = entity[searchBy as keyof T];
    if (searchValue) {
      queryParams.equals = {...queryParams.equals, [searchBy]: searchValue as string};
    } else {
      return false;
    }
    if (instanceOfBaseCompanyBranch(entity)) {
      const companyBranchValue = entity['companyBranch'];
      if (companyBranchValue) {
        queryParams.equals = {...queryParams.equals, 'companyBranchName': companyBranchValue.name};
      } else {
        return false;
      }
    }
    const id = entity['id' as keyof T] as string;
    if (id) {
      queryParams.notEquals = {id};
    }
    if (!touched) {
      return false;
    }
    const result = await this.queryReference(0, 1, queryParams);
    return result.totalCount > 0;
  }
}
