import {Payload} from '@core/models/payload';
import {HttpResponse} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {inject} from '@angular/core';
import {ApiService} from '@shared/core/services/api.service';
import {CrudEndPoint} from '@core/const';
import {IdentifierReference} from '@core/models/identifier-reference';
import {ApiQueryParams} from '@core/models';

export abstract class QueryApiService<T, R extends IdentifierReference> {

  apiService = inject(ApiService);

  protected readonly endPoint: CrudEndPoint;

  protected constructor(endpoint: CrudEndPoint) {
    this.endPoint = endpoint;
  }

  protected async getWithParams(params: ApiQueryParams, showSpinner: boolean = true): Promise<Payload<T | R>> {
    const response: HttpResponse<T> = (await firstValueFrom(this.apiService.get(this.endPoint, params, 'response',
      undefined, showSpinner))) as HttpResponse<T>;
    return {
      body: response.body as T[],
      totalCount: Number(response.headers.get('X-Total-Count'))
    };
  }

  getDefaultParams(): ApiQueryParams {
    return {};
  }

  async queryReference(page: number, size: number, params?: ApiQueryParams,
                       referenceOnly: boolean = true): Promise<Payload<R>> {
    const queryParams: ApiQueryParams = {
      ...this.getDefaultParams(),
      ...params,
      page: page,
      size: size,
      referenceOnly: referenceOnly
    };
    return (await this.getWithParams(queryParams, false)) as Payload<R>;
  }

  async getReference(id: number): Promise<R> {
    return (await firstValueFrom(this.apiService.get(`${this.endPoint}/reference/${id}`, undefined, undefined, undefined, false))) as R;
  }
}
